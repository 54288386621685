import axios from "axios";
import Cookies from "js-cookie";

const urlMassWikiAPILambda = "https://ff5ppq2y0e.execute-api.us-west-2.amazonaws.com";
const urlMassWikiAPI = "https://masswiki-next.us-west-2.elasticbeanstalk.com";
const urlMassWikiAPILocal = "http://localhost:8000";


const axiosGet = (url, params) => {
    const cookiesInfo = Cookies.get()
    const userInfo = {
        "user-email": cookiesInfo.userEmail || "",
        "user-key": cookiesInfo.userKey || "",
    }
    return axios.get(url, {params: params, headers: userInfo}).then(res => res.data)
};

const axiosPost = (url, data) => {
    const cookiesInfo = Cookies.get()
    const userInfo = {
        "user-email": cookiesInfo.userEmail || "",
        "user-key": cookiesInfo.userKey || "",
    }
    return axios.post(url, data, {headers: userInfo}).then(res => res.data)
}

export const urlMassWiki = {
    uploadFile: `${urlMassWikiAPI}/uploads`,

    analysis: {
        submitOneFile: (data) =>
            axiosPost(`${urlMassWikiAPI}/analysis/one_spectral_file`, data),

        submitOneSpectrum: (data) =>
            axiosPost(`${urlMassWikiAPI}/analysis/one_spectrum`, data),

        getData: (wikiId) =>
            axiosGet(`${urlMassWikiAPI}/analysis/get_data`, {wiki_id: wikiId}),
    },

    method: {
        getMethodByWikiId: (wikiId) =>
            axios.get(`${urlMassWikiAPI}/method/get`,
                {params: {wiki_id: wikiId}}).then(res => res.data),

        getAllMethod: (data) =>
            axios.get(`${urlMassWikiAPI}/method/get_all_list`,)
                .then(res => res.data),

        createMethod: (name, description) =>
            axios.post(`${urlMassWikiAPI}/method/create`, {name: name, description: description || ""})
                .then(res => res.data),
    },

    user: {
        add: (name, email, password) =>
            axios.post(`${urlMassWikiAPI}/user/add`, {name: name, email: email, password: password})
                .then(res => res.data),
        activate: (email, activation_code) =>
            axios.post(`${urlMassWikiAPI}/user/activate`, {email: email, activation_code: activation_code})
                .then(res => res.data),
        login: (email, password) =>
            axios.get(`${urlMassWikiAPI}/user/login`, {params: {email: email, password: password}})
                .then(res => res.data),
        changePassword: (email, password, new_password) =>
            axios.post(`${urlMassWikiAPI}/user/change_password`, {
                email: email,
                password: password,
                new_password: new_password
            })
                .then(res => res.data),
        resetPassword: (email, reset_code, new_password) =>
            axios.post(`${urlMassWikiAPI}/user/change_password`, {
                email: email,
                reset_code: reset_code,
                new_password: new_password
            }).then(res => res.data),

        forgetPassword: (email) =>
            axios.post(`${urlMassWikiAPI}/user/forget_password`, {email: email})
                .then(res => res.data),
    },

    annotation: {
        getOneByWikiId: (wikiId) =>
            axiosPost(`${urlMassWikiAPI}/annotation_library/get_annotation_list`, {
                spec_uid_list: [wikiId],
                get_details: true
            }).then(data => data[0]),
        getManyByWikiId: (wikiIdList) =>
            axiosPost(`${urlMassWikiAPI}/annotation_library/get_annotation_list`, {
                spec_uid_list: wikiIdList,
                get_details: false
            }),

        submit: (data) =>
            axiosPost(`${urlMassWikiAPI}/annotation_library/add_one_annotation`, {
                spec_wiki_id: data.spec_wiki_id,
                method_id: data.method_id,
                rt_normalized: data.rt_normalized,
                spectrum: data.spectrum,
                mol_data: data.mol_data,
                annotation_data: data.annotation_data,
                metadata: data.metadata,
            })
    },

    referenceLibrary: {
        getOneByWikiId: (wikiId) =>
            axiosPost(`${urlMassWikiAPI}/reference_library/get_spectra_data`, {
                id_list: [wikiId],
                get_details: true
            }).then(data => data[0]),
        // getManyByWikiId: (wikiIdList) =>
        //     axiosPost(`${urlMassWikiAPI}/reference_library/get_spectra_data`, {
        //         id_list: wikiIdList,
        //         get_details: false
        //     }),
        rtPredict: (data) =>
            axiosPost(`${urlMassWikiAPI}/reference_library/rt_prediction`, data[0]),
    },

    internalLibrary: {
        getOneByWikiId: (wikiId) =>
            axiosGet(`${urlMassWikiAPI}/analysis/get_data`, {wiki_id: wikiId})
    },

    experimentalLibrary: {
        getLibraryInfo: (data) =>
            axios.post(`${urlMassWikiAPI}/experimental_library/get_ms2_data`, {
                method_id: data.method_id,
                id_list: data.id_list
            }).then(res => res.data),
        getSelectedLibraryInfo: (data) =>
            axios.post(`${urlMassWikiAPI}/experimental_library/get_spectrum_detail`, {
                method_id: data.method_id,
                study_id: data.study_id,
                file_name: data.file_name,
                scan: data.scan
            }).then(res => res.data),
    },

    universe: {
        search_universe_identity: (data) =>
            axios.post(`${urlMassWikiAPILambda}/search/universe_identity`, {...data, top_n: 100})
                .then(res => res.data),

        get_universe_spectrum: (unique_scan_id) =>
            axios.get(`${urlMassWikiAPILambda}/get/universe_spectrum/${unique_scan_id}`)
                .then(res => res.data),

        search_wcmc_identity: (data) =>
            axios.post(`${urlMassWikiAPILambda}/search/wcmc_identity`, {...data, cutoff: 0.75})
                .then(res => res.data),
    },

    binbase: {
        getAllLibrary: (target) =>
            axios.get(`${urlMassWikiAPI}/binbase/get_library_list`, {params: {source: target, version: "LCB2023"}})
                .then(res => res.data),

        update_binbase: (wiki_id, method, version) =>
            axios.post(`${urlMassWikiAPI}/binbase/update_method`, {
                wiki_id: wiki_id,
                method: method,
                version: version,
            }).then(res => res.data),

        getEic: (data) => {
            const params = {
                method: encodeURIComponent(data.method),
                version: encodeURIComponent(data.version),
                splash: encodeURIComponent(data.splash)
            }
            return axios.get(`${urlMassWikiAPI}/binbase/get_eic`, {params})
                .then(res => res.data)
        }
    },
}
