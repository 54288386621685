import {Button, Col, Row, Tabs, Checkbox, Tag, Space, Input} from 'antd';
import {Parser} from '@json2csv/plainjs';
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {SearchOutlined, FrownOutlined, CheckOutlined} from "@ant-design/icons";
import {useAtom} from "jotai";

import {atomGlobalExperiment, atomWikiID} from "../../../Data/Atoms";
import VirtualTable from "../../../Library/VirtualTable";
import SpectralListPlot from "./SpectralListPlot";

const getColumn = (stateGlobalAnalysisData) => {
    let columns = []
    let columnsTemplate = [{
        title: "Name",
        dataIndex: "name",
        width: 250,
        sorter: (a, b) => (a["name"] ? a["name"] : "").localeCompare(b["name"] ? b["name"] : ""),
    }, {
        title: "Adduct",
        dataIndex: "adduct",
        width: 80,
        sorter: (a, b) => (a["adduct"] ? a["adduct"] : "").localeCompare(b["adduct"] ? b["adduct"] : ""),
    }, {
        title: "RT",
        dataIndex: "rt",
        render: (_, record) => (record.rt === undefined ? 0.0 : record.rt).toFixed(2),
        sorter: (a, b) => (a["rt"] ? a["rt"] : 0.) - (b["rt"] ? b["rt"] : 0.),
        width: 60,
    }, {
        title: "Precursor m/z",
        dataIndex: "precursor_mz",
        render: (_, record) => (record.precursor_mz === undefined ? 0.0 : record.precursor_mz).toFixed(3),
        sorter: (a, b) => (a["precursor_mz"] ? a["precursor_mz"] : 0.) - (b["precursor_mz"] ? b["precursor_mz"] : 0.),
        width: 80,
    }, {
        title: "Entropy",
        dataIndex: "entropy",
        render: (_, record) => (record.entropy === undefined ? 0.0 : record.entropy).toFixed(1),
        sorter: (a, b) => (a["entropy"] ? a["entropy"] : 0.) - (b["entropy"] ? b["entropy"] : 0.),
        width: 40,
    }, {
        title: "Identity search score",
        dataIndex: "identity_score",
        defaultSortOrder: 'descend',
        render: (_, record) => {
            if (record.is_manual_annotated) {
                return <Tag color={"green"}> Manual annotated </Tag>
            } else if (record["identity_score"] === undefined) {
                return "-"
            } else {
                return record["identity_score"].toFixed(3)
            }
        },
        sorter: (a, b) => (a["identity_score"] ? a["identity_score"] : 0.) - (b["identity_score"] ? b["identity_score"] : 0.),
        width: 80,
    }, {
        title: "Fuzzy search score",
        dataIndex: "fuzzy_score",
        render: (_, record) => (record["fuzzy_score"] === undefined ? "-" : record["fuzzy_score"].toFixed(3)),
        sorter: (a, b) => (a["fuzzy_score"] ? a["fuzzy_score"] : 0.) - (b["fuzzy_score"] ? b["fuzzy_score"] : 0.),
        width: 80,
    }]
    // If binbase exists in the metadata, add the binbase column
    if ((stateGlobalAnalysisData.metadata || {}).binbase) {
        columns = [{
            title: "",
            key: "idx",
            render: (text, record, index) => `${index + 1}`,
            width: 30,
        }, ...columnsTemplate, {
            title: "BinBase",
            dataIndex: "raw_splash",
            render: (_, record) => <a
                href={`http://binview.metabolomics.us.s3-website-us-west-2.amazonaws.com/binview/compound/${stateGlobalAnalysisData.metadata.binbase.method}/${stateGlobalAnalysisData.metadata.binbase.version}/${record["raw_splash"]}`}
                target="_blank">Link</a>,
            width: 60,
        }]
    } else {
        columns = [{
            title: "",
            key: "idx",
            render: (text, record, index) => `${index + 1}`,
            width: 30,
        }, {
            title: "Scan",
            dataIndex: "scan",
            render: (text, record, index) => `${record.scan}`,
            sorter: (a, b) => (a["scan"] ? a["scan"] : 0.) - (b["scan"] ? b["scan"] : 0.),
            width: 30,
        }, ...columnsTemplate, {
            title: "Annotation method",
            dataIndex: "annotation_method",
            defaultSortOrder: 'descend',
            width: 80,
        }, {
            title: "Peaks source",
            dataIndex: "peaks_source",
            width: 80,
            sorter: (a, b) => (a["peaks_source"] ? a["peaks_source"] : "").localeCompare(b["peaks_source"] ? b["peaks_source"] : ""),
        }]
    }

    columns.map(k => ({
        key: k.dataIndex,
        ...k
    }));
    return columns;
};

export default () => {
    const navigate = useNavigate();
    const [stateWikiId] = useAtom(atomWikiID);
    const [stateGlobalExperiment,] = useAtom(atomGlobalExperiment);

    const [stateHighlightRow, setStateHighlightRow] = useState({row: 0, version: 0});
    const [stateTableSorter, setTableSorter] = useState({field: "identity_score", order: "descend"});

    const [stateTableData, setTableData] = useState([]);
    /////////////////////////////////////////////////////////////////
    // Determine the display mode
    const [stateDisplayMode, setDisplayMode] = useState("user");
    useEffect(() => {
        if ((stateGlobalExperiment.metadata || {}).source === "binbase") {
            console.log("Setting display mode to binbase")
            setDisplayMode("binbase")
        } else {
            setDisplayMode("user")
        }
    }, [stateGlobalExperiment])

    /////////////////////////////////////////////////////////////////
    // Update table data
    useEffect(() => {
        setTableData(stateGlobalExperiment.spectra);
    }, [stateGlobalExperiment, stateDisplayMode]);

    /////////////////////////////////////////////////////////////////
    // For exporting the table data
    const [stateTextFile, setStateTextFile] = useState(null);
    useEffect(() => {
        if (stateTableData && stateTableData.length > 0) {
            const keyOutput = Object.keys(stateTableData[0]).filter(k => k[0] != "_");
            // console.log(keyOutput);
            const stateDataForExport = stateTableData.map(d => Object.fromEntries(Object.keys(d).filter(k => k[0] != "_").map(k => [k, d[k]])));
            // console.log(stateDataForExport);
            const parser = new Parser();
            const csv = parser.parse(stateDataForExport);
            const data = new Blob([csv], {type: 'text/plain'});
            if (stateTextFile !== null) {
                window.URL.revokeObjectURL(stateTextFile);
            }
            const textFile = window.URL.createObjectURL(data);
            setStateTextFile(textFile);
        }
    }, [stateTableData]);

    /////////////////////////////////////////////////////////////////////////
    // For displaying the empty spectra
    const [stateShowEmptySpectra, setShowEmptySpectra] = useState(true);
    const [stateSearchText, setSearchText] = useState("");

    /////////////////////////////////////////////////////////////////
    // For displaying the extra buttons
    const tabExtra = <>
        <Space>
            <Input prefix={<SearchOutlined/>} placeholder="Searching" onChange={(e) => setSearchText(e.target.value)}
                   value={stateSearchText}/>
            <Checkbox checked={stateShowEmptySpectra} onChange={(e) => setShowEmptySpectra(e.target.checked)}>
                Show spectra without MS/MS peaks
            </Checkbox>
            {
                stateTextFile ? <>
                    <Button href={stateTextFile} download="masswiki_result.csv"
                            style={{marginRight: "8px"}}>Export
                        results</Button>
                </> : <></>
            }
        </Space>
    </>

    const [stateDisplayTableData, setDisplayTableData] = useState([]);
    useEffect(() => {
        let displayTableData = stateTableData.filter(d => stateShowEmptySpectra || d["peak_number"] > 0);
        if (stateSearchText) {
            const searchText = stateSearchText.toLowerCase();
            displayTableData = displayTableData.filter(d => {
                if (d["name"] && d["name"].toLowerCase().includes(searchText)) {
                    return true;
                } else if (d["adduct"] && d["adduct"].toLowerCase().includes(searchText)) {
                    return true;
                } else if (d["rt"] && d["rt"].toString().toLowerCase().includes(searchText)) {
                    return true;
                } else if (d["precursor_mz"] && d["precursor_mz"].toString().toLowerCase().includes(searchText)) {
                    return true;
                }
            });
        }
        setDisplayTableData(displayTableData);
    }, [stateTableData, stateShowEmptySpectra, stateSearchText]);

    /////////////////////////////////////////////////////////////////
    const columns = getColumn(stateGlobalExperiment);
    // Jump to the selected row
    const jumpToRow = () => {
        // console.log("Jumping to row", stateWikiId.id2, stateTableSorter, stateDisplayTableData)
        const tableData = [...stateDisplayTableData];
        if (stateTableSorter.order === "ascend") {
            // Get sorter from columns
            const sorter = columns.find(c => c.dataIndex === stateTableSorter.field);
            // Sort table data
            tableData.sort((a, b) => sorter.sorter(a, b));
        } else if (stateTableSorter.order === "descend") {
            // Get sorter from columns
            const sorter = columns.find(c => c.dataIndex === stateTableSorter.field);
            // Sort table data
            tableData.sort((a, b) => sorter.sorter(b, a));
        }
        const row_id = tableData.findIndex(d => d.wiki_id.toString() === `${stateWikiId.id1}/${stateWikiId.id2}`);
        setStateHighlightRow({row: row_id, version: stateHighlightRow.version + 1});
    }
    useEffect(() => {
        jumpToRow();
    }, [stateTableSorter]);
    let location = useLocation();
    useEffect(() => {
        if (stateWikiId.id2 && (location.state !== "from_click" || !location.state) && stateDisplayTableData.length > 0) {
            jumpToRow();
        }
    }, [stateWikiId.id2, stateDisplayTableData])

    /////////////////////////////////////////////////////////////////
    // For displaying the table
    const tabItems = [
        {
            key: 'list',
            label: 'List',
            children: <> <VirtualTable
                scrollToRow={stateHighlightRow}
                size={'small'}
                height={250} vid={'spectra-list-table'}
                columns={columns} dataSource={stateDisplayTableData}
                rowClassName={record => {
                    return (stateWikiId.id1 + "/" + stateWikiId.id2 || "").toString() === (record.wiki_id || "").toString() ? 'row-active' : '';
                }}
                onRow={record => ({
                    onClick: event => (navigate(`/${record.wiki_id}`, {replace: false, state: "from_click"}))
                })}
                onChange={(pagination, filters, sorter, extra) => {
                    if (sorter) {
                        setTableSorter(sorter)
                    }
                }}
            /></>,
        },
        {
            key: 'plot',
            label: 'Plot',
            children: <>
                <SpectralListPlot plotData={stateDisplayTableData}/>
            </>,
        },
    ]

    return <>
        <Row justify="end">
        </Row>
        <Row>
            <Col span={24}>
                <Tabs centered defaultActiveKey="list" items={tabItems} tabBarExtraContent={tabExtra}
                      onChange={(key) => {
                          if (key === "list") {
                              setTimeout(() => {
                                  jumpToRow();
                              }, 200);
                          }
                      }}
                />
            </Col>
        </Row>
    </>;
};