import {Col, Row, Space, Timeline, Tabs, message, notification, Button} from 'antd';
import React, {useState, useContext, useEffect} from 'react';
import {atom, useAtom} from "jotai";
import Plot from 'react-plotly.js';


import {atomGlobalSpectrum} from "../../../Data/Atoms";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {useRequest} from "ahooks";
import VirtualTable from "../../../Library/VirtualTable";

const columnsMerged = [
    {
        title: 'Species',
        dataIndex: 'species',
        key: 'species',
        ellipsis: true,
        width: 100,
        sorter: (a, b) => a.species.localeCompare(b.species),
    }, {
        title: 'Organ',
        dataIndex: 'organ',
        key: 'organ',
        ellipsis: true,
        width: 100,
        sorter: (a, b) => a.organ.localeCompare(b.organ),
    }, {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        ellipsis: true,
        width: 60,
        sorter: (a, b) => a.count - b.count,
        render: (_, record) => record.count.toFixed(0),
    }, {
        title: 'Ratio (%)',
        dataIndex: 'ratio',
        key: 'ratio',
        ellipsis: true,
        width: 60,
        sorter: (a, b) => a.ratio - b.ratio,
        render: (_, record) => record.ratio < 1 ? "<1" : record.ratio.toFixed(0),
    },]
const columnsOrgan = [
    {
        title: 'Organ',
        dataIndex: 'organ',
        key: 'organ',
        ellipsis: true,
        width: 100,
        sorter: (a, b) => a.organ.localeCompare(b.organ),
    }, {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        ellipsis: true,
        width: 60,
        sorter: (a, b) => a.count - b.count,
        render: (_, record) => record.count.toFixed(0),
    }, {
        title: 'Ratio (%)',
        dataIndex: 'ratio',
        key: 'ratio',
        ellipsis: true,
        width: 60,
        sorter: (a, b) => a.ratio - b.ratio,
        render: (_, record) => record.ratio < 1 ? "<1" : record.ratio.toFixed(0),
    },]
const columnsSpecies = [
    {
        title: 'Species',
        dataIndex: 'species',
        key: 'species',
        ellipsis: true,
        width: 100,
        sorter: (a, b) => a.species.localeCompare(b.species),
    }, {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        ellipsis: true,
        width: 60,
        sorter: (a, b) => a.count - b.count,
        render: (_, record) => record.count.toFixed(0),
    }, {
        title: 'Ratio (%)',
        dataIndex: 'ratio',
        key: 'ratio',
        ellipsis: true,
        width: 60,
        sorter: (a, b) => a.ratio - b.ratio,
        render: (_, record) => record.ratio < 1 ? "<1" : record.ratio.toFixed(0),
    }]

export const atomWcmcSearchResult = atom({})

export default () => {
    const [messageApi, messageApiContextHolder] = message.useMessage();
    const [stateOrganTableData, setOrganTableData] = useState([])
    const [stateOrganPieData, setOrganPieData] = useState({values: [], labels: [], type: 'pie'})
    const [stateSpeciesTableData, setSpeciesTableData] = useState([])
    const [stateSpeciesPieData, setSpeciesPieData] = useState({values: [], labels: [], type: 'pie'})
    const [stateMergedTableData, setMergedTableData] = useState([])
    const [stateMergedPieData, setMergedPieData] = useState({labels: [], parents: [], values: [], type: 'sunburst'})

    // Download the annotations
    const [stateAtomGlobalSpectrumData,] = useAtom(atomGlobalSpectrum);
    const [stateAtomWcmcSearchResult, setAtomWcmcSearchResult] = useAtom(atomWcmcSearchResult);
    useEffect(() => {
        console.log("stateAtomWcmcSearchResult", stateAtomWcmcSearchResult)
    }, [stateAtomWcmcSearchResult]);
    const getSearchUniverseIdentity = useRequest(urlMassWiki.universe.search_wcmc_identity, {
        manual: true,
        onSuccess: (data) => {
            console.log("getSearchUniverseIdentity-Success", data)
            setAtomWcmcSearchResult(data)

            // For species data
            const speciesData = []
            const speciesPieData = {values: [], labels: [], type: 'pie', textinfo: 'label'}
            if (data.species.length > 0) {
                for (const [key, value] of Object.entries(data.species)) {
                    speciesData.push({species: value[0], count: value[1], ratio: value[1] / value[2] * 100, key: key})
                    speciesPieData.values.push(value[1])
                    speciesPieData.labels.push(value[0])
                }
            }
            setSpeciesTableData(speciesData)
            setSpeciesPieData(speciesPieData)

            // For organ data
            const organData = []
            const organPieData = {values: [], labels: [], type: 'pie', textinfo: 'label'}
            if (data.organ.length > 0) {
                for (const [key, value] of Object.entries(data.organ)) {
                    organData.push({organ: value[0], count: value[1], ratio: value[1] / value[2] * 100, key: key})
                    organPieData.values.push(value[1])
                    organPieData.labels.push(value[0])
                }
            }
            setOrganTableData(organData)
            setOrganPieData(organPieData)

            // For merged data
            const mergedData = []
            const mergedPieData = {ids: [], labels: [], parents: [], values: [], type: 'sunburst'}
            if (data.merged.length > 0) {
                for (const [key, value] of Object.entries(data.species)) {
                    mergedPieData.ids.push(value[0])
                    mergedPieData.labels.push(value[0])
                    mergedPieData.parents.push("")
                    mergedPieData.values.push(value[1])
                }
                for (const [key, value] of Object.entries(data.merged)) {
                    mergedData.push({
                        species: value[0],
                        organ: value[1],
                        count: value[2],
                        ratio: value[2] / value[3] * 100,
                        key: key
                    })
                    mergedPieData.ids.push(value[0] + "-" + value[1])
                    mergedPieData.labels.push(value[1])
                    mergedPieData.parents.push(value[0])
                    mergedPieData.values.push(value[2])
                }
            }
            // console.log("mergedPieData", mergedPieData)
            setMergedTableData(mergedData)
            setMergedPieData(mergedPieData)
        },
        onError: (error, params) => {
            console.log("getSearchUniverseIdentity-Error", error, params)
            setAtomWcmcSearchResult({organ: [], species: []})
            setOrganTableData([])
            setOrganPieData({values: [], labels: [], type: 'pie'})
            setSpeciesTableData([])
            setSpeciesPieData({values: [], labels: [], type: 'pie'})
            setMergedTableData([])
            setMergedPieData({labels: [], parents: [], values: [], type: 'sunburst'})
            messageApi.open({
                key: "wcmc-search-error",
                type: "error",
                content: (error.response ? JSON.parse(error.response.data) : null) || "Unknown error occurred when searching the WCMC database.",
                // duration: 10
            })
        }
    });
    useEffect(() => {
        const specData = stateAtomGlobalSpectrumData.spectrum || {}
        if (specData.peaks && specData.peaks.length > 0) {
            // console.log(specData)
            getSearchUniverseIdentity.run({
                charge: specData.charge,
                precursor_mz: specData.precursor_mz,
                peaks: specData.peaks,
            });
        }
    }, [stateAtomGlobalSpectrumData])

    return <div>
        <br/>
        {messageApiContextHolder}
        <Row justify={"space-around"}>
            <Col span={20}>
                {getSearchUniverseIdentity.loading ?
                    <>Searching all of the WCMC spectra, this might need a few seconds...</> :
                    <Plot
                        style={{position: 'relative', width: '100%', height: '400px'}}
                        useResizeHandler={true}
                        data={[{
                            ...stateMergedPieData, "leaf": {"opacity": 0.8},
                            "marker": {"line": {"width": 1}},
                            "branchvalues": 'total'
                        }]}
                        layout={{
                            showlegend: false,
                            title: "",
                            autosize: true,
                            margin: {l: 10, r: 10, b: 10, t: 10, pad: 0},
                        }}
                        config={{}}/>
                }
                <VirtualTable
                    vid={"wcmc-search-table-merged"}
                    loading={getSearchUniverseIdentity.loading ? {
                        tip: <>Searching all of the WCMC spectra, this might need a few seconds...</>
                    } : false}
                    height={200}
                    size={'small'}
                    columns={columnsMerged} dataSource={stateMergedTableData}/>
            </Col>
        </Row>
        <br/>
        <Row justify={"space-around"}>
            <Col span={10}>
                {getSearchUniverseIdentity.loading ?
                    <>Searching all of the WCMC spectra, this might need a few seconds...</> :
                    <Plot
                        style={{position: 'relative', width: '100%', height: '300px'}}
                        useResizeHandler={true}
                        data={[stateSpeciesPieData]}
                        layout={{
                            showlegend: false,
                            title: "Species",
                            autosize: true,
                            margin: {l: 55, r: 10, b: 30, t: 30, pad: 0},
                        }}
                        config={{}}/>
                }
                <VirtualTable
                    vid={"wcmc-search-table-species"}
                    loading={getSearchUniverseIdentity.loading ? {
                        tip: <>Searching all of the WCMC spectra, this might need a few seconds...</>
                    } : false}
                    height={200}
                    size={'small'}
                    columns={columnsSpecies} dataSource={stateSpeciesTableData}/>
            </Col>
            <Col span={10}>
                {getSearchUniverseIdentity.loading ?
                    <>Searching all of the WCMC spectra, this might need a few seconds...</> :
                    <Plot
                        style={{position: 'relative', width: '100%', height: '300px'}}
                        useResizeHandler={true}
                        data={[stateOrganPieData]}
                        layout={{
                            showlegend: false,
                            title: "Organ",
                            autosize: true,
                            margin: {l: 55, r: 10, b: 30, t: 30, pad: 0},
                        }}
                        config={{}}/>
                }
                <VirtualTable
                    vid={"wcmc-search-table-organ"}
                    loading={getSearchUniverseIdentity.loading ? {
                        tip: <>Searching all of the WCMC spectra, this might need a few seconds...</>
                    } : false}
                    height={200}
                    size={'small'}
                    columns={columnsOrgan} dataSource={stateOrganTableData}/>
            </Col>
        </Row>
    </div>
}