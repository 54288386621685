import {atom, useAtom} from "jotai/index";
import {atomGlobalExperiment, atomGlobalReferenceSpectrum, atomGlobalSpectrum} from "../../../Data/Atoms";
import {useAutoGet} from "../../Global/Message";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {zip} from "../../../Library/Tools";
import {useEffect} from "react";

// Atom for Annotation Data
export const atomAnnotationSearch = atom([]);
export const atomAnnotationSearchSelected = atom([]);
export const atomAnnotationSearchSelectedTrigger = atom("");
export const atomAnnotationSearchType = atom("identity_search");

const atomAnnotationSearchTrigger = atom([]);

export default () => {
    const [stateGlobalRunData,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [stateAnnotationSearchType,] = useAtom(atomAnnotationSearchType);

    const [, setAnnotationSearchTableData] = useAtom(atomAnnotationSearch);
    const [, setAnnotationSearchSelected] = useAtom(atomAnnotationSearchSelected);
    const [, setGlobalReferenceSpectrum] = useAtom(atomGlobalReferenceSpectrum);

    // For get user annotation search table data
    const [, setAnnotationSearchTableDataTrigger] = useAtom(atomAnnotationSearchTrigger);
    useEffect(() => {
        if (stateGlobalSpectrum.analysis) {
            const userAnnotationScoreList = ((stateGlobalSpectrum.analysis || {}).annotation_library || {})[stateAnnotationSearchType] || [];
            setAnnotationSearchTableDataTrigger(userAnnotationScoreList.map((d) => d[0]));
        }
    }, [stateGlobalSpectrum, stateAnnotationSearchType]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [getAnnotationMultiData, messageGetAnnotationMultiData] =
        useAutoGet(
            atomAnnotationSearchTrigger,
            (trigger) => trigger.length > 0,
            urlMassWiki.annotation.getManyByWikiId,
            () => setAnnotationSearchTableData([]),
            (responseData) => {
                const userAnnotationScoreList = ((stateGlobalSpectrum.analysis || {}).annotation_library || {})[stateAnnotationSearchType] || [];

                // Get the analysis chromatogram data from stateGlobalRunData
                const globalMethodId = ((stateGlobalRunData.metadata || {}).method || {}).library_id;
                console.log(globalMethodId)
                const annotationData = zip(responseData, userAnnotationScoreList);
                const globalSpectrumRt = (stateGlobalSpectrum.spectrum || {}).rt_normalized || (stateGlobalSpectrum.spectrum || {}).rt;
                let tableData = annotationData.map((d, i) => {
                    const rowAnnotation = d[0];
                    const dScore = d[1][1];
                    return {
                        key: rowAnnotation.library_id,
                        library_id: rowAnnotation.library_id,
                        spec_uid: rowAnnotation.spec_uid,
                        mol_name: rowAnnotation.mol_name,
                        mol_adduct: rowAnnotation.mol_adduct,
                        method_id: rowAnnotation.method_id,
                        deltaRt: rowAnnotation.method_id === globalMethodId ? rowAnnotation.rt - globalSpectrumRt : null,
                        deltaMz: rowAnnotation.precursor_mz - (stateGlobalSpectrum.spectrum || {}).precursor_mz,
                        score: dScore,
                        user_name: rowAnnotation.user_name,
                        mol_smiles: rowAnnotation.mol_smiles,
                    }
                });
                // Filter key is not null
                tableData = tableData.filter((d) => !!d.key);
                setAnnotationSearchTableData(tableData)
            },
            "urlMassWiki.annotation.getManyByWikiId",
            "Loading annotation data..."
        );

    const [getAnnotationSingleData, messageGetAnnotationSingleData] =
        useAutoGet(
            atomAnnotationSearchSelectedTrigger,
            (trigger) => !!trigger,
            urlMassWiki.annotation.getOneByWikiId,
            () => setGlobalReferenceSpectrum({}),
            (data) => {
                setAnnotationSearchSelected(data)
                data = data[0]
                const userInfo = data.data.user
                const molInfo = data.data.mol

                // console.log("data", data)
                const refData = {
                    library_id: data.id,
                    spec_uid: data.spectrum.spec_uid,
                    method_id: data.spectrum.method_id,
                    spectrum: data.spectrum,
                    metadata: {
                        "Annotator": userInfo.name,
                        "Original retention Time": data.spectrum.rt_original,
                        "Normalized retention Time": data.spectrum.rt,
                        "Molecule Name": molInfo.name,
                        "smiles": molInfo.smiles,
                        "Adduct": molInfo.adduct,
                        "Comment": data.data.annotation.comments,
                        "Chromatography Method ID": data.spectrum.method_id,
                    }
                }
                setGlobalReferenceSpectrum(refData)
            },
            "urlMassWiki.annotation.getOneByWikiId",
            "Loading selected annotation data..."
        )

    return <>
        {messageGetAnnotationMultiData}
        {messageGetAnnotationSingleData}
    </>
}