import {message, notification} from 'antd';
import React, {useEffect} from "react";
import {useRequest} from "ahooks";
import {useAtom} from "jotai";


// Hooks for loading message
const useMessageLoading = () => {
    const [messageApi, messageApiContextHolder] = message.useMessage();
    const triggerMessageLoading = (key, message) => {
        messageApi.open({type: "loading", content: message, duration: 0, key: key});
    }
    const destroyMessageLoading = (key) => {
        messageApi.destroy(key);
    }

    return [messageApiContextHolder, triggerMessageLoading, destroyMessageLoading];
}

const useMessageError = () => {
    const [notificationApi, messageErrorContextHolder] = notification.useNotification();
    const triggerMessageError = (message, title) => {
        console.log("triggerMessageError", message)
        let notificationMessage = message;
        if (message.message) {
            notificationMessage = message.message;
        }
        if (message.response && message.response.data && message.response.data.detail) {
            notificationMessage = JSON.stringify(message.response.data.detail);
        }
        notificationApi.error({
            message: title ? title : 'Error encountered',
            description: notificationMessage,
            duration: 0,
            placement: 'top',
        });
    }

    return [messageErrorContextHolder, triggerMessageError];
}

export const useAutoGet = (atomTrigger, funcCheckTrigger, funcGetRequest, funcBefore, funcSuccess, messageKey, messageLoading, funcError) => {
    const [getRequest, messageContextHolder] = useEasyGet(funcGetRequest, funcBefore, funcSuccess, messageKey, messageLoading, funcError);
    const [stateTrigger,] = useAtom(atomTrigger);

    useEffect(() => {
        if (funcCheckTrigger(stateTrigger)) {
            getRequest.run(stateTrigger);
        } else {
            getRequest.cancel();
            funcBefore();
        }
    }, [stateTrigger]);

    return [getRequest, messageContextHolder];
}

export const useAutoGetWithPreprocess = (atomTrigger, funcCheckTrigger, funcPreprocess, funcGetRequest, funcBefore, funcSuccess, messageKey, messageLoading, funcError) => {
    const [getRequest, messageContextHolder] = useEasyGet(funcGetRequest, funcBefore, funcSuccess, messageKey, messageLoading, funcError);
    const [stateTrigger,] = useAtom(atomTrigger);

    useEffect(() => {
        if (funcCheckTrigger(stateTrigger)) {
            const data = funcPreprocess(stateTrigger);
            getRequest.run(data);
        } else {
            getRequest.cancel();
            funcBefore();
        }
    }, [stateTrigger]);

    return [getRequest, messageContextHolder];
}
export const useEasyGet = (funcGetRequest, funcBefore, funcSuccess, messageKey, messageLoading, funcError) => {
    const [messageApiContextHolder, triggerMessageLoading, destroyMessageLoading] = useMessageLoading();
    const [messageErrorContextHolder, triggerMessageError] = useMessageError();
    const messageContextHolder = <>
        {messageApiContextHolder}
        {messageErrorContextHolder}
    </>;

    funcBefore = funcBefore ? funcBefore : () => {
    };
    funcError = funcError ? funcError : (error) => {
        console.log("getRequest.error", messageKey, getRequest);
        triggerMessageError(error, `Error encountered in ${messageKey}`);
    }
    const getRequest = useRequest(funcGetRequest, {
        manual: true,
        onBefore: funcBefore,
        onSuccess: (data) => {
            // console.log("getRequest.onSuccess", messageKey);
            funcSuccess(data);
        },
        onError: funcError
    });

    useEffect(() => {
        if (getRequest.loading) {
            triggerMessageLoading(messageKey, messageLoading ? messageLoading : "Loading...");
        } else {
            destroyMessageLoading(messageKey);
        }
    }, [getRequest.loading]);

    return [getRequest, messageContextHolder];
}

export const useEasyGetRequestDebounceWait = (funcGetRequest, funcBefore, funcSuccess, debounceWait, messageKey, messageLoading) => {
    const [messageApiContextHolder, triggerMessageLoading, destroyMessageLoading] = useMessageLoading();
    const [messageErrorContextHolder, triggerMessageError] = useMessageError();
    const messageContextHolder = <>
        {messageApiContextHolder}
        {messageErrorContextHolder}
    </>;

    const getRequest = useRequest(funcGetRequest, {
        manual: true,
        debounceWait: debounceWait,
        onBefore: () => {
            // console.log("getRequest.before", messageKey);
            funcBefore();
        },
        onSuccess: (data) => {
            // console.log("getRequest.onSuccess", messageKey);
            funcSuccess(data);
        },
        onError: (error) => {
            console.log("getRequest.error", messageKey, getRequest);
            triggerMessageError(error, `Error encountered in ${messageKey}`);
        }
    });

    useEffect(() => {
        if (getRequest.loading) {
            triggerMessageLoading(messageKey, messageLoading ? messageLoading : "Loading...");
        } else {
            destroyMessageLoading(messageKey);
        }
    }, [getRequest.loading]);

    return [getRequest, messageContextHolder];
}