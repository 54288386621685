import {Col, Row, Spin} from 'antd';
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAtom} from 'jotai'

import {
    atomGlobalExperiment,
    atomGlobalSpectrum,
    atomWebsiteMode,
    SPECTRA_TYPE,
    PERMISSION_TYPE,
    atomWikiID
} from "../../Data/Atoms";
import TemplateCard from "../../Library/TemplateCard";

import SpectralList from "./SpectralList/SpectralListTable";
import SpectraViewer from "./SpectraViewer/SpectraViewer";
import ReferenceLibraryResult from "./ReferenceLibrary/LibrarySearchResult";
import LibraryInformation from "./LibraryInformation/LibraryInformation";
import AnnotationLibraryResult from "./AnnotationLibrary/AnnotationSearchResult";
import ExperimentalLibraryResult from "./ExperimentalLibrary/ExperimentalLibraryResult";
import EIC from "./EIC/EICViewer";
import UserAnnotationSubmitModal from "./SubmitAnnotation/UserAnnotationSubmitModal";
import UserAnnotation from "./SubmitAnnotation/UserAnnotation";
import WCMCSearch from "./MetadataLibrary/WCMCSearch";
import UniverseSearch from "./MetadataLibrary/UniverseSearch";
import ProcessingPage from "../StatusPage/ProcessingPage";
import BinbaseEIC from "../Binbase/BinbaseEIC";
import {InternalLibrarySearchResult} from "./InternalLibrary/LibrarySearchResult";

export const SetWikiID = () => {
    const [atomWikiId, setAtomWikiId] = useAtom(atomWikiID);
    useEffect(() => {
        console.log("SetWikiID", atomWikiId);
    }, [atomWikiId]);

    const params = useParams();
    useEffect(() => {
        if (params.wikiId) {
            const wikiId1 = params.wikiId;
            if (params['*']) {
                const wikiId2 = params['*'];
                setAtomWikiId({id1: wikiId1, id2: wikiId2});
            } else {
                setAtomWikiId({id1: wikiId1, id2: ""});
            }
        } else {
            setAtomWikiId({id1: "", id2: ""});
        }
    }, [params]);
}

const SpectrumMain = () => {
    const [stateWebsiteMode, setWebsiteMode] = useAtom(atomWebsiteMode)
    useEffect(() => {
        console.log(stateWebsiteMode)
    }, [stateWebsiteMode])
    const [stateGlobalAnalysisData,] = useAtom(atomGlobalExperiment)
    useEffect(() => {
        if (stateGlobalAnalysisData.metadata && stateGlobalAnalysisData.metadata.binbase) {
            setWebsiteMode({...stateWebsiteMode, mode: SPECTRA_TYPE.LCBINBASE});
        } else if (stateGlobalAnalysisData.metadata) {
            setWebsiteMode({...stateWebsiteMode, mode: SPECTRA_TYPE.USER});
        } else {
            setWebsiteMode({...stateWebsiteMode, mode: SPECTRA_TYPE.SINGLE_SPECTRUM});
        }
    }, [stateGlobalAnalysisData]);

    return <>
        <UserAnnotationSubmitModal/>
        {
            stateWebsiteMode.mode === SPECTRA_TYPE.LCBINBASE || stateWebsiteMode.mode === SPECTRA_TYPE.USER ? <>
                <Row justify={"center"} gutter={[16, 16]} style={{marginRight: 0, marginLeft: 0}}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <TemplateCard card_id={'card-spectra_list'} card_title={'Spectra list'}
                                      children={<SpectralList/>}/>
                    </Col>
                </Row></> : <></>
        }
        <Row justify={"center"} gutter={[16, 16]} style={{marginRight: 0, marginLeft: 0}}>
            <Col xs={24} sm={24} md={24} lg={15}>
                <TemplateCard card_id={'card-annotation_search_result'}
                              card_title={'Annotation library search results'}
                              children={<AnnotationLibraryResult/>}/>

                <TemplateCard card_id={'card-reference_library_result'} card_title={'Reference library search results'}
                              children={<ReferenceLibraryResult/>}/>

                <TemplateCard card_id={'card-internal_search_result'}
                              card_title={'Internal library search results'}
                              children={<InternalLibrarySearchResult/>}/>

                <TemplateCard card_id={'card-experimental_library_search_result'}
                              card_title={'Experimental library search'}
                              children={<ExperimentalLibraryResult/>}/>

                <TemplateCard card_id={'card-metadata_library_wcmc_search'} card_title={'WCMC spectra matches'}
                              children={<WCMCSearch/>}/>

                <TemplateCard card_id={'card-metadata_library_universe_search'} card_title={'Public repository matches'}
                              children={<UniverseSearch/>}/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={9}>
                <TemplateCard card_id={'card-spectra_viewer'} card_title={'Spectra Viewer'}
                              children={<SpectraViewer/>}/>
                <TemplateCard card_id={'card-ms2_library_eic'} card_title={'EIC'}
                              children={<EIC/>}/>
                <TemplateCard card_id={'card-user_annotation'} card_title={'User annotations'}
                              children={<UserAnnotation/>}/>

                <TemplateCard card_id={'card-library_information'} card_title={'Library information'}
                              children={<LibraryInformation/>}/>
            </Col>
        </Row>
    </>
    return <>
        <UserAnnotationSubmitModal/>
        {
            stateWebsiteMode.mode === SPECTRA_TYPE.LCBINBASE || stateWebsiteMode.mode === SPECTRA_TYPE.USER ? <>
                <Row justify={"center"} gutter={[16, 16]} style={{marginRight: 0, marginLeft: 0}}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <TemplateCard card_id={'card-spectra_list'} card_title={'Spectra list'}
                                      children={<SpectralList/>}/>
                    </Col>
                </Row></> : <></>
        }
        {
            stateWebsiteMode.mode === SPECTRA_TYPE.LCBINBASE ? <>
                <Row justify={"center"} gutter={[16, 16]} style={{marginRight: 0, marginLeft: 0}}>
                    <Col xs={24} sm={24} md={24} lg={15}>
                        <TemplateCard card_id={'card-library_search_result'} card_title={'Library search results'}
                                      children={<ReferenceLibraryResult/>}/>

                        <TemplateCard card_id={'card-annotation_search_result'}
                                      card_title={'User annotation search results'}
                                      children={<AnnotationLibraryResult/>}/>

                        <TemplateCard card_id={'card-wcmc_search'} card_title={'WCMC spectra matches'}
                                      children={<WCMCSearch/>}/>

                        <TemplateCard card_id={'card-universe_search'} card_title={'Public repository matches'}
                                      children={<UniverseSearch/>}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9}>
                        <TemplateCard card_id={'card-spectra_viewer'} card_title={'Spectra Viewer'}
                                      children={<SpectraViewer/>}/>

                        <TemplateCard card_id={'card-binbase_eic'} card_title={'EIC'}
                                      children={<BinbaseEIC/>}/>

                        <TemplateCard card_id={'card-library_information'} card_title={'Library information'}
                                      children={<LibraryInformation/>}/>

                        <TemplateCard card_id={'card-user_annotation'} card_title={'User annotations'}
                                      children={<UserAnnotation/>}/>
                    </Col>
                </Row>
            </> : stateWebsiteMode.mode === SPECTRA_TYPE.USER ? <>
                <Row justify={"center"} gutter={[16, 16]} style={{marginRight: 0, marginLeft: 0}}>
                    <Col xs={24} sm={24} md={24} lg={15}>
                        <TemplateCard card_id={'card-annotation_search_result'}
                                      card_title={'User annotation search results'}
                                      children={<AnnotationLibraryResult/>}/>

                        <TemplateCard card_id={'card-library_search_result'} card_title={'Library search results'}
                                      children={<ReferenceLibraryResult/>}/>

                        <TemplateCard card_id={'card-wcmc_search'} card_title={'WCMC spectra matches'}
                                      children={<WCMCSearch/>}/>

                        <TemplateCard card_id={'card-universe_search'} card_title={'Public repository matches'}
                                      children={<UniverseSearch/>}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9}>
                        <TemplateCard card_id={'card-spectra_viewer'} card_title={'Spectra Viewer'}
                                      children={<SpectraViewer/>}/>

                        <TemplateCard card_id={'card-user_annotation'} card_title={'User annotations'}
                                      children={<UserAnnotation/>}/>

                        <TemplateCard card_id={'card-library_information'} card_title={'Library information'}
                                      children={<LibraryInformation/>}/>

                    </Col>
                </Row>
            </> : <>
                <Row justify={"center"} gutter={[16, 16]} style={{marginRight: 0, marginLeft: 0}}>
                    <Col xs={24} sm={24} md={24} lg={15}>
                        <TemplateCard card_id={'card-annotation_search_result'}
                                      card_title={'User annotation search results'}
                                      children={<AnnotationLibraryResult/>}/>

                        <TemplateCard card_id={'card-library_search_result'} card_title={'Library search results'}
                                      children={<ReferenceLibraryResult/>}/>

                        <TemplateCard card_id={'card-wcmc_search'} card_title={'WCMC spectra matches'}
                                      children={<WCMCSearch/>}/>

                        <TemplateCard card_id={'card-universe_search'} card_title={'Public repository matches'}
                                      children={<UniverseSearch/>}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9}>
                        <TemplateCard card_id={'card-spectra_viewer'} card_title={'Spectra Viewer'}
                                      children={<SpectraViewer/>}/>

                        <TemplateCard card_id={'card-user_annotation'} card_title={'User annotations'}
                                      children={<UserAnnotation/>}/>

                        <TemplateCard card_id={'card-library_information'} card_title={'Library information'}
                                      children={<LibraryInformation/>}/>

                    </Col>
                </Row>
            </>
        }
    </>
}

const Main = () => {
    const [stateGlobalExperiment,] = useAtom(atomGlobalExperiment)
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum)
    return <>
        <SetWikiID/>
        {
            stateGlobalExperiment.status ? <>
                {
                    (stateGlobalExperiment.status || "processing") === "processing" || (stateGlobalExperiment.status || "error") === "error" ?
                        <ProcessingPage/> :
                        <SpectrumMain/>
                }
            </> : <>
                {
                    stateGlobalSpectrum.status ? <SpectrumMain/> : <Spin/>
                }
            </>
        }
    </>;
}

export default Main;