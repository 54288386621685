import React from "react";

import AnalysisData from "./GlobalExperiment";
import SpectrumData from "./GlobalSpectrum";
import WikiId from "./WikiId";
import BinbaseEICData from "./BinbaseEICData";

export default () => {
    return <>
        <WikiId/>
        <AnalysisData/>
        <SpectrumData/>
        <BinbaseEICData/>
    </>;
}
