import React, {useEffect, useState, useContext} from "react";
import {useAtom} from "jotai";
import {atomGlobalExperiment, atomGlobalSpectrum} from "./Atoms";
import {urlMassWiki} from "../Config/MassWikiUrl";
import {useEasyGet} from "../Pages/Global/Message";

import {atomEICData, defaultEICData} from "../Pages/Spectrum/EIC/EICViewerData";

const BinbaseEIC = () => {
    const [stateGlobalExperiment,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [, setEICData] = useAtom(atomEICData);
    const [getBinbaseEic, messageGetBinbaseEic] = useEasyGet(
        urlMassWiki.binbase.getEic,
        () => {
            setEICData({
                ...defaultEICData,
                isShow: true,
                isLoading: true,
            })
        },
        (data) => {
            // console.log('Binbase EIC data:', data);
            const eicData = data.data.eic || {};
            const rtData = data.data || {};
            setEICData({
                ...defaultEICData,
                isShow: true,
                isLoading: false,
                rt: eicData.retention_times,
                intensity: eicData.intensities,
                rtApex: null,
                rtLeft: rtData.rt_left,
                rtRight: rtData.rt_right,
                displayIntensityMax: Math.max(...eicData.intensities),
            })
        },
        "urlMassWiki.binbase.getEic",
        "Loading EIC data..."
    );

    useEffect(() => {
        const metaData = stateGlobalExperiment.metadata;
        const specData = stateGlobalSpectrum.spectrum;
        console.log('Binbase EIC:', metaData, specData)
        if (metaData && metaData.binbase && specData) {
            const binbaseMetaData = metaData.binbase;
            const binbaseData = {
                method: binbaseMetaData.method,
                version: binbaseMetaData.version,
                splash: specData.splash,
            }
            if (binbaseData.method && binbaseData.version && binbaseData.splash) {
                getBinbaseEic.run(binbaseData);
            }
        }
    }, [stateGlobalExperiment, stateGlobalSpectrum]);

    return <>
        {messageGetBinbaseEic}
    </>
};
export default BinbaseEIC;