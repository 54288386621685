import {Col, Row, Tabs, Space, Table, Tag} from 'antd';
import React, {useEffect, useState, useContext} from "react";
import {useAtom} from "jotai";
import Plot from 'react-plotly.js';

import {atomEICData} from "./EICViewerData";

const plotLayout = {
    xaxis: {title: {text: 'RT',}},
    yaxis: {title: {text: 'Intensity',}},
    autosize: true,
    hovermode: "closest",
    showlegend: false,
    margin: {l: 55, r: 10, b: 30, t: 10, pad: 0},
};

const plotConfig = {
    responsive: true,
    scrollZoom: true,
    displaylogo: false,
    modeBarButtonsToRemove: ['select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'zoom2d', 'pan2d'],
}

export default () => {
    const [statePlotData, setStatePlotData] = useState({
        data: [], layout: plotLayout, config: plotConfig
    });
    const [statePlotVersion, setStatePlotVersion] = useState(0);

    const [stateEICData,] = useAtom(atomEICData);
    useEffect(() => {
        const rt = stateEICData.rt || [];
        const intensity = stateEICData.intensity || [];
        const rtApex = stateEICData.rtApex;
        const rtLeft = stateEICData.rtLeft;
        const rtRight = stateEICData.rtRight;
        const maxIntensity = stateEICData.displayIntensityMax || 0;

        if (rt.length > 0 && intensity.length > 0) {
            const traceMain = {
                x: rt,
                y: intensity,
                mode: 'lines+markers',
                name: 'EIC',
                marker: {
                    color: '#4e74f7',
                    size: 4
                }
            }
            let traceRtApex = {}
            if (rtApex) {
                traceRtApex = {
                    x: [rtApex, rtApex],
                    y: [0, Math.max(...intensity)],
                    mode: 'lines',
                    marker: {
                        color: 'rgb(234, 153, 153)',
                        size: 12
                    },
                }
            }
            let rangeLeft = {}, rangeRight = {};
            if (rtLeft && rtRight) {
                rangeLeft = {
                    x: [rtLeft, rtLeft],
                    y: [0, Math.max(...intensity)],
                    mode: 'lines',
                    marker: {
                        color: 'rgb(234, 153, 153)',
                        size: 12
                    },
                }
                rangeRight = {
                    x: [rtRight, rtRight],
                    y: [0, Math.max(...intensity)],
                    mode: 'lines',
                    marker: {
                        color: 'rgb(234, 153, 153)',
                        size: 12
                    },
                }
            }
            const layout = {
                ...statePlotData.layout,
                xaxis: {
                    title: 'RT',
                    range: [rt[0], rt[rt.length - 1]],
                },
                yaxis: {
                    title: 'Intensity',
                    range: [0, maxIntensity],
                },
            }
            setStatePlotData({
                data: [traceRtApex, traceMain, rangeLeft, rangeRight],
                layout: layout,
                config: statePlotData.config
            })
            setStatePlotVersion(statePlotVersion + 1)
        } else {
        }
    }, [stateEICData]);

    if (!stateEICData.isShow) {
        return null
    } else {
        return <>
            <Row justify={"center"}>
                <Col span={1}>
                </Col>
                <Col span={23}>
                    {stateEICData.isLoading ? <> Loading EIC data... </> :
                        <Plot style={{position: 'relative', width: '100%', height: '300px'}}
                              useResizeHandler={true}
                              revision={statePlotVersion}
                              data={statePlotData.data}
                              layout={statePlotData.layout}
                              config={statePlotData.config}/>
                    }
                </Col>
            </Row>
        </>
    }
};