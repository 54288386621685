import {Button, Modal, Col, Row, Tabs, Space, Form, Alert, Input} from 'antd';
import React, {useState, useContext, useEffect} from 'react';
import {atom, useAtom} from "jotai";
import {useCookies} from "react-cookie";

import {urlMassWiki} from "../../Config/MassWikiUrl";
import {useEasyGet} from "../Global/Message";

import {atomUserInfo} from "../../Data/Atoms";
import {atomUserInfoTrigger} from "../../Data/Atoms";


export const UserLogin = () => {
    const [form] = Form.useForm();
    const [stateUserInfo, setUserInfo] = useAtom(atomUserInfo);
    const [stateUserInfoTrigger, setUserInfoTrigger] = useAtom(atomUserInfoTrigger);
    const [stateInfoType, setInfoType] = useState("login");// login, activate, register, forgot, reset
    const [stateIsCorrect, setIsCorrect] = useState(true);

    // Deal with get request
    const [getLogin, messageLogin] = useEasyGet(urlMassWiki.user.login,
        () => setIsCorrect(true),
        (data) => {
            if (data.status === "success") {
                setCookieUser('userEmail', data.email, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setCookieUser('userName', data.name, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setCookieUser('userKey', data.key, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setUserInfo({
                    ...stateUserInfo,
                    email: data.email,
                    name: data.name,
                    key: data.key,
                })
                setUserInfoTrigger(false);
            }
        },
        "userLogin", "Logging in...", (error) => setIsCorrect(false));
    const [getRegister, messageRegister] = useEasyGet(urlMassWiki.user.add,
        () => setIsCorrect(true),
        (data) => {
            if (data.status === "success") {
                setInfoType("activate");
            }
        },
        "userRegister", "Registering...", (error) => setIsCorrect(false));
    const [getActivate, messageActivate] = useEasyGet(urlMassWiki.user.activate,
        () => setIsCorrect(true),
        (data) => {
            if (data.status === "success") {
                setCookieUser('userEmail', data.email, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setCookieUser('userName', data.name, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setCookieUser('userKey', data.key, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setUserInfo({
                    ...stateUserInfo,
                    email: data.email,
                    name: data.name,
                    key: data.key,
                })
                setUserInfoTrigger(false);
            }
        },
        "userActivate", "Activating...", (error) => setIsCorrect(false));
    const [getForget, messageForget] = useEasyGet(urlMassWiki.user.forgetPassword,
        () => setIsCorrect(true),
        (data) => {
            if (data.status === "success") {
                setInfoType("reset");
            }
        },
        "userForget", "Sending reset code...", (error) => setIsCorrect(false));
    const [getReset, messageReset] = useEasyGet(urlMassWiki.user.resetPassword,
        () => setIsCorrect(true),
        (data) => {
            if (data.status === "success") {
                setCookieUser('userEmail', data.email, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setCookieUser('userName', data.name, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setCookieUser('userKey', data.key, {expires: new Date(Date.now() + 86400 * 1000 * 365 * 10)});
                setUserInfo({
                    ...stateUserInfo,
                    email: data.email,
                    name: data.name,
                    key: data.key,
                })
                setUserInfoTrigger(false);
            }
        },
        "userReset", "Resetting password...", (error) => setIsCorrect(false));

    // Load cookies
    const [cookiesUser, setCookieUser, removeCookieUser] = useCookies(['userEmail', 'userName', 'userKey']);
    useEffect(() => {
        setUserInfo({
            ...stateUserInfo,
            email: cookiesUser.userEmail || "",
            name: cookiesUser.userName || "",
            key: cookiesUser.userKey || "",
        })
    }, [])

    // Submit the form
    const onFinish = (data) => {
        const formData = form.getFieldsValue();
        console.log(formData, data);
        if (stateInfoType === "login") {
            getLogin.run(formData.userEmail, formData.userPassword);
        } else if (stateInfoType === "register") {
            getRegister.run(formData.userName, formData.userEmail, formData.userPassword);
        } else if (stateInfoType === "activate") {
            getActivate.run(formData.userEmail, formData.userActivationCode);
        } else if (stateInfoType === "forgot") {
            getForget.run(formData.userEmail);
        } else if (stateInfoType === "reset") {
            getReset.run(formData.userEmail, formData.userResetCode, formData.userNewPassword);
        }
    }

    const handleCancel = () => {
        setUserInfoTrigger(false);
    }

    const showLoginModal = () => {
        setUserInfoTrigger(true);
    }

    const [stateTabKey, setTabKey] = useState("login");
    const tabItems = [
        {
            key: 'login',
            label: 'Login',
        },
        {
            key: 'register',
            label: 'Register',
        },
        {
            key: 'forgot',
            label: 'Forgot password',
        },
    ];
    const onTabChange = (key) => {
        setTabKey(key);
        setInfoType(key);
    };

    // This is the Modal for user login
    const userLoginModal = <>
        <Modal title="You need an account to use this feature" open={stateUserInfoTrigger} onCancel={handleCancel}
               footer={null}
               style={{maxWidth: "50em",}} destroyOnClose>
            {messageLogin}
            {messageRegister}
            {messageActivate}
            {messageForget}
            {messageReset}
            <Row style={{paddingBottom: "1em"}}>
                <Col span={24}>
                    <Tabs centered items={tabItems} onChange={onTabChange}
                          activeKey={stateTabKey}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <>
                        {
                            !!!stateIsCorrect ? <div style={{paddingBottom: "1em"}}>
                                <Alert type="error" showIcon message="Error encountered, please try again."/>
                            </div> : null
                        }
                    </>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form form={form} name="basic" labelCol={{span: 8,}} wrapperCol={{span: 16,}}
                          initialValues={{remember: true,}} onFinish={onFinish}>
                        {
                            stateInfoType === "login" ? <div style={{paddingBottom: "0.5em"}}>
                                If you don't have an account, please register first.
                            </div> : null
                        }
                        {
                            stateInfoType === "activate" ? <div style={{paddingBottom: "0.5em"}}>
                                Please check your email for the activation code.
                            </div> : null
                        }
                        {
                            stateInfoType === "reset" ? <div style={{paddingBottom: "0.5em"}}>
                                Please check your email for the reset code.
                            </div> : null
                        }
                        <Form.Item label="E-mail address" name="userEmail" type="email"
                                   rules={[{required: true, message: 'Please input your E-mail address!',},]}>
                            <Input/>
                        </Form.Item>

                        {
                            stateInfoType === "register" ?
                                <Form.Item label="Full name" name="userName"
                                           rules={[{required: true, message: 'Please input your name!',},]}>
                                    <Input/>
                                </Form.Item> : null
                        }
                        {
                            stateInfoType === "login" || stateInfoType === "register" ?
                                <Form.Item label="Password" name="userPassword" type="password"
                                           rules={[{required: true, message: 'Please input your password!',},]}>
                                    <Input type="password"/>
                                </Form.Item> : null
                        }
                        {
                            stateInfoType === "activate" ?
                                <Form.Item label="Activation Code" name="userActivationCode"
                                           rules={[{required: true, message: 'Please input your activation code!',},]}>
                                    <Input/>
                                </Form.Item> : null
                        }
                        {
                            stateInfoType === "reset" ? <>
                                <Form.Item label="Reset Code" name="userResetCode"
                                           rules={[{required: true, message: 'Please input your reset code!',},]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="New Password" name="userNewPassword" type="password"
                                           rules={[{required: true, message: 'Please input your password!',},]}>
                                    <Input type="password"/>
                                </Form.Item> </> : null
                        }

                        <Form.Item wrapperCol={{offset: 10, span: 12,}}>
                            <Button type="primary" htmlType="submit">Next</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    </>

    const userManageModal = <>
        <Modal title="Manage your account" open={stateUserInfoTrigger} onCancel={handleCancel}
               footer={null}
               style={{maxWidth: "50em",}}>
            <Row style={{paddingTop: "1em"}}>
                <Col span={24}>
                    <Button onClick={() => {
                        removeCookieUser('userEmail');
                        removeCookieUser('userName');
                        removeCookieUser('userKey');
                        setUserInfo({
                            ...stateUserInfo,
                            email: "",
                            name: "",
                            key: "",
                        })
                        setUserInfoTrigger(false);
                    }}>Logout</Button>
                </Col>
            </Row>
        </Modal>
    </>

    return <>
        {stateUserInfo.key === "" ? userLoginModal : userManageModal}
    </>
}
