import {Button, Col, Row, Space, Upload, Spin, Progress, Radio, Divider} from 'antd';
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {atom, useAtom} from 'jotai'
import {BarChartOutlined, FileSearchOutlined, UploadOutlined, CheckOutlined} from "@ant-design/icons";
import axios from "axios";
import {urlMassWiki} from "../../Config/MassWikiUrl";

export const atomUploadFileList = atom([])

const uploadFileToS3 = async (file, metadata, onError, onSuccess, onProgress) => {
    const url = urlMassWiki.s3ForUploadRawFile(file.uid)
    let headers = {}
    Object.keys(metadata).map(key => {
        const newKey = "x-amz-meta-" + key
        headers = {...headers, [newKey]: metadata[key]}
    })
    return axios.put(url, file, {
        headers: {
            'Content-Type': 'application/octet-stream', ...headers
        }, onUploadProgress: progressEvent => {
            const {loaded, total} = progressEvent;
            onProgress({percent: Math.round((loaded / total) * 100),}, file);
        }
    }).then(res => {
        // console.log("uploadFileToS3:", res)
        onSuccess(res, file);
        return {
            metadata: metadata, ...file
        }
    }).catch((error) => {
        onError(error);
    });
}

const PanelUploadFileStatus = ({stateUpload, stateUploadProgress}) => {
    const styleIcon = {
        fontSize: "3rem", marginTop: "0em", marginBottom: "0em", color: "rgb(22, 119, 255)",
    }
    const styleText = {
        fontSize: "1rem", marginTop: "0em", marginBottom: "0em", color: "rgba(0, 0, 0, 0.85)",
    }
    const styleHint = {
        fontSize: "0.9rem", marginTop: "0em", marginBottom: "0em", color: "rgba(0, 0, 0, 0.45)",
    }
    if (stateUpload === "init") {
        return <>
            <p style={styleIcon}><UploadOutlined/></p>
            <p style={styleText}>Click or drag files to this area to upload</p>
            <p style={styleHint}>.mgf and .msp file is supported.</p>
        </>
    } else if (stateUpload === "uploading") {
        return <>
            {/*<Progress percent={Math.floor(stateUploadProgress * 100)} status="active"/>*/}
            <Spin/>
            <p className="ant-upload-text">Uploading files</p>
        </>
    } else if (stateUpload === "done") {
        return <>
            <p className="ant-upload-text">
                You have uploaded your spectral file to the Mass.Wiki server. Please set the parameters below.
            </p>
        </>
    }
}

export default () => {
    const [stateUploadState, setUploadState] = useState({
        status: "init", //  'done' | 'uploading' | 'init'
        progress: 0, // 0-1
    })
    const [stateUploadFileList, setUploadFileList] = useAtom(atomUploadFileList)
    useEffect(() => {
        console.log("stateUploadFileList:", stateUploadFileList)
    }, [stateUploadFileList])

    const uploaderProps = {
        multiple: true,
        listType: "picture",
        showUploadList: true,
        maxCount: 1,
        accept: ".mzML,.mgf,.msp", // action: "https://s3.us-west-2.amazonaws.com/upload.mass.wiki",
        iconRender: (file) => {
            if (file.status === "done") {
                return <BarChartOutlined/>
            } else if (file.status === "uploading") {
                return <Spin/>
            } else {
                return <UploadOutlined/>
            }
        },
        action: urlMassWiki.uploadFile,
        // customRequest: ({file, onError, onSuccess, onProgress}) => {
        //     const fileMetadata = {name: file.name}
        //     uploadFileToS3(file, fileMetadata, onError, onSuccess, onProgress)
        // },
        onChange: ({file, fileList, event}) => {
            // console.log("onChange:", file, fileList, event)
            const fileStatus = file.status
            if (fileStatus === "error" || fileStatus === "removed") {
                setUploadState({status: "init", progress: 0})
                setUploadFileList(fileList.map(file => (file.response || {}).file_name))
            } else if (fileStatus === "done") {
                setUploadState({status: "done", progress: 100})
                setUploadFileList(fileList.map(file => (file.response || {}).file_name))
            } else if (fileStatus === "uploading") {
                setUploadState({status: "uploading", progress: file.percent / 100})
            }
        }
    };

    return <>
        <Upload.Dragger
            {...uploaderProps}
            style={{
                width: "100%", height: "100%", marginBottom: "0.1em", borderRadius: "0.2em", fontSize: "1.9rem",
            }}>
            <PanelUploadFileStatus stateUpload={stateUploadState.status}
                                   stateUploadProgress={stateUploadState.progress}/>
        </Upload.Dragger>
    </>
}

