import React, {useEffect} from "react";
import {useAtom} from 'jotai'

import {urlMassWiki} from "../Config/MassWikiUrl";
import {
    atomGlobalReferenceSpectrum, atomGlobalSpectrumTrigger, atomGlobalSpectrum,
} from "./Atoms";
import {useEasyGet} from "../Pages/Global/Message";

export default () => {
    const [stateAtomGlobalSpectrumData, setAtomGlobalSpectrumData] = useAtom(atomGlobalSpectrum);
    const [stateAtomGlobalSpectrumDataTrigger,] = useAtom(atomGlobalSpectrumTrigger);
    const [, setAtomGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrum);

    /////////////////////////////////////////////////////////////////////////////////
    // For debug
    useEffect(() => {
        console.log("stateAtomGlobalSpectrumData", stateAtomGlobalSpectrumData);
    }, [stateAtomGlobalSpectrumData])

    /////////////////////////////////////////////////////////////////////////////////
    const [getMassWikiSpectrumData, messageGetMassWikiSpectrumData] = useEasyGet(
        urlMassWiki.analysis.getData, () => {
            setAtomGlobalReferenceSpectrumData({});
            setAtomGlobalSpectrumData({})
        },
        (data) => setAtomGlobalSpectrumData(data),
        "urlMassWiki.chromMethod.getAnalysisData -> stateAtomGlobalSpectrumData",
        "Loading MassWiki spectrum data...");

    useEffect(() => {
        if (stateAtomGlobalSpectrumDataTrigger.wikiId) {
            getMassWikiSpectrumData.run(stateAtomGlobalSpectrumDataTrigger.wikiId);
        } else {
            getMassWikiSpectrumData.cancel();
        }
    }, [stateAtomGlobalSpectrumDataTrigger])

    return <>
        {messageGetMassWikiSpectrumData}
    </>
}

