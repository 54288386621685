import {atom} from 'jotai'

export const atomWikiID = atom({id1: "", id2: "",});

// Atom for Global Run Data
export const atomGlobalExperiment = atom({spectra: []});
export const atomGlobalExperimentTrigger = atom({wikiId: "", version: 0});

// Atom for Global Spectrum Data
export const atomGlobalSpectrum = atom({spectrum: {}, analysis: {}});
export const atomGlobalSpectrumTrigger = atom({wikiId: "", version: 0});

// Atom for Reference Spectrum Data
export const atomGlobalReferenceSpectrum = atom({});

// Atom for User Info
export const atomUserInfo = atom({email: "", name: "", key: ""});
export const atomUserInfoTrigger = atom(false);

export const PERMISSION_TYPE = {PUBLIC: "public", INTERNAL: "internal"}
export const SPECTRA_TYPE = {
    USER: "user", SINGLE_SPECTRUM: "single_spectrum", LCBINBASE: "lcbinbase",
}
export const atomWebsiteMode = atom({permission: PERMISSION_TYPE.PUBLIC, mode: SPECTRA_TYPE.USER});
