import {Button, Col, Row, Space, Upload, Spin, Progress, Radio, message, Divider} from 'antd';
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {atom, useAtom} from 'jotai'
import {urlMassWiki} from "../../Config/MassWikiUrl";
import {useEasyGet} from "../Global/Message";
import {atomWebsiteMode, PERMISSION_TYPE} from "../../Data/Atoms";
import InputSpectrum, {atomSpectrum} from "./InputSpectrum";


export default () => {
    const navigate = useNavigate();
    const [stateSpectrum,] = useAtom(atomSpectrum);

    const [stateWebsiteMode,] = useAtom(atomWebsiteMode)
    const [getSubmitJob, messageContextHolder] = useEasyGet(
        urlMassWiki.analysis.submitOneSpectrum,
        () => {
        },
        (data) => {
            console.log(data)
            if (data.status === "success") {
                const wiki_id = data.wiki_id
                message.success("Job submitted successfully: " + wiki_id)
                navigate("/" + wiki_id)
            } else {
                console.error(data)
                message.error("Failed to submit job: " + data.message)
            }
        },
        "analysis.submitJob", "Submitting analysis, please wait for a few seconds...");
    const submitAnalysisJob = () => {
        console.log(stateWebsiteMode, PERMISSION_TYPE.INTERNAL)
        let data = {
            method_id: "",
            rt: 0,
            charge: stateSpectrum.charge,
            precursor_mz: stateSpectrum.precursorMz,
            peaks: stateSpectrum.peaks,
            reference_library_id_list: [],
        }

        if (stateWebsiteMode.permission === PERMISSION_TYPE.INTERNAL) {
            data.reference_library_id_list = ["agilent", "gnps", "mona", "msdial", "nist23"]
        } else if (stateWebsiteMode.permission === PERMISSION_TYPE.PUBLIC) {
            data.reference_library_id_list = ["gnps", "mona", "msdial"]
        }
        // console.log(data)
        getSubmitJob.run(data)
    }

    const [stateShowSubmit, setShowSubmit] = useState(false)
    useEffect(() => {
        console.log(stateSpectrum)
        if (stateSpectrum.charge !== 0 && stateSpectrum.precursorMz && stateSpectrum.peaks && stateSpectrum.peaks.length > 0) {
            setShowSubmit(true)
        } else {
            setShowSubmit(false)
        }
    }, [stateSpectrum])

    return <>
        {messageContextHolder}
        <Divider plain style={{marginTop: "0px", marginBottom: "8px"}}>Spectrum to analyze</Divider>
        <Row justify={"center"} align={"middle"} gutter={[16, 16]}
             style={{marginRight: 0, marginLeft: 0}}>
            <Col xs={24} sm={24} md={2} lg={4}/>
            <Col xs={24} sm={24} md={20} lg={16}>
                <div style={{width: "100%", minWidth: "8em", marginBottom: "0em", fontSize: "1rem"}}>
                    <InputSpectrum/>
                </div>
            </Col>
            <Col xs={24} sm={24} md={2} lg={4}/>
        </Row>
        <Divider plain style={{marginTop: "8px", marginBottom: "8px"}}></Divider>
        <Row justify="center" style={{marginTop: "8px", marginBottom: "8px"}}>
            <Button type="primary"
                    disabled={!stateShowSubmit}
                    onClick={() => {
                        submitAnalysisJob()
                        // console.log(stateSpectrum)
                    }}>{
                stateShowSubmit ? "Submit" : "Please input spectrum"
            }</Button>
        </Row>
    </>
}

