import React, {useEffect} from "react";
import {useAtom} from 'jotai'

import {urlMassWiki} from "../Config/MassWikiUrl";
import {atomGlobalExperiment, atomGlobalExperimentTrigger, atomGlobalSpectrumTrigger, atomWikiID,} from "./Atoms";
import {useEasyGet} from "../Pages/Global/Message";

export default () => {
    const [atomWikiId] = useAtom(atomWikiID);
    const [stateGlobalAnalysisTrigger,] = useAtom(atomGlobalExperimentTrigger);
    const [stateGlobalSpectrumTrigger, setGlobalSpectrumTrigger] = useAtom(atomGlobalSpectrumTrigger);

    /////////////////////////////////////////////////////////////////////////////////
    // For update atomGlobalAnalysisData
    const [stateGlobalAnalysisData, setGlobalAnalysisData] = useAtom(atomGlobalExperiment);
    useEffect(() => {
        console.log("stateGlobalAnalysisData", stateGlobalAnalysisData);
    }, [stateGlobalAnalysisData])

    const [getMassWikiAnalysisData, messageGetMassWikiAnalysisData] = useEasyGet(
        urlMassWiki.analysis.getData,
        () => setGlobalAnalysisData({}),
        (data) => {
            let displayMode = "user";
            if ((data.metadata || {}).source === "binbase") {
                displayMode = "binbase";
            }
            if (data.status === "success") {
                data.spectra.map(d => {
                    // Parse the identity score
                    const annotation_library_search_identity_score = d["annotation_library_search-identity_score"] || 0;
                    const reference_library_search_identity_score = d["reference_library_search-identity_score"] || 0;
                    if (annotation_library_search_identity_score > reference_library_search_identity_score) {
                        d["identity_score"] = annotation_library_search_identity_score;
                        d["name"] = d["annotation_library_search-name"];
                        d["adduct"] = d["annotation_library_search-adduct"];
                        d["annotation_method"] = "Annotation library search";
                    } else {
                        d["identity_score"] = reference_library_search_identity_score;
                        d["name"] = d["reference_library_search-name"];
                        d["adduct"] = d["reference_library_search-adduct"];
                        d["annotation_method"] = "Reference library search";
                    }
                    if (d["identity_score"] < 0.75) {
                        d["name"] = "";
                        d["adduct"] = "";
                        d["annotation_method"] = "";
                    }

                    // Parse the fuzzy score
                    const annotation_library_search_fuzzy_score = d["annotation_library_search-fuzzy_score"] || 0;
                    const reference_library_search_fuzzy_score = d["reference_library_search-fuzzy_score"] || 0;
                    if (annotation_library_search_fuzzy_score > reference_library_search_fuzzy_score) {
                        d["fuzzy_score"] = annotation_library_search_fuzzy_score;
                    } else {
                        d["fuzzy_score"] = reference_library_search_fuzzy_score;
                    }
                    if (displayMode === "binbase") {
                        d["name"] = d["annotation-name"];
                        d["adduct"] = d["annotation-adduct"];
                        d["identity_score"] = reference_library_search_identity_score;
                        d["fuzzy_score"] = reference_library_search_fuzzy_score;
                        d["is_manual_annotated"] = false;
                    }
                    d["key"] = d["wiki_id"];
                })
                setGlobalAnalysisData(data)
                if (atomWikiId.id2) {
                    setGlobalSpectrumTrigger({
                        wikiId: atomWikiId.id1 + "/" + atomWikiId.id2,
                        version: stateGlobalSpectrumTrigger.version + 1
                    });
                }
            } else {
                setGlobalAnalysisData(data)
            }
        },
        "urlMassWiki.analysis.getAnalysisData -> stateGlobalAnalysisData",
        "Loading MassWiki analysis data...")

    useEffect(() => {
        if (stateGlobalAnalysisTrigger.wikiId) {
            getMassWikiAnalysisData.run(stateGlobalAnalysisTrigger.wikiId);
        } else {
            getMassWikiAnalysisData.cancel();
        }
    }, [stateGlobalAnalysisTrigger]);


    return <>
        {messageGetMassWikiAnalysisData}
    </>
}
