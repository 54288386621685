import { Col, Row, Space, Timeline, Card, Avatar, Empty, List } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useAtom } from 'jotai';
import { usePlotMolecule } from '../../../Library/PlotMolecule';
import { atomAnnotationSearchSelected } from '../AnnotationLibrary/AnnotationSearchResultData';
import { UserOutlined } from '@ant-design/icons';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

/**
 * Test Data for comments
 * @param {*} props 
 * @returns 
 */
const comment_list = [
  // {
  //   comment: 'This Annotation is Very good',
  // },
  // {
  //   comment: 'Best in the world',
  // },
  // {
  //   comment: 'Awesome Job!!!!!!!!!!!!!!!',
  // }
];

const UserAnnotationCard = (props) => {
  const element = props.element;
  const annotationMol = element.mol || {};
  const annotationAnnotation = element.annotation || {};
  const annotationUser = element.user || {};
  const time = props.time;

  const [contextPlotMolecule, setPlotMoleculeSmiles] = usePlotMolecule({ width: 150, height: 120 });
  const refPlot = useRef(null);

  const [upvotes, setUpvotes] = useState(0); // Track upvotes
  const [downvotes, setDownvotes] = useState(0); // Track downvotes
  const [userVote, setUserVote] = useState(null); // Track user's vote (null, 'up', or 'down')

  useEffect(() => {
    if (annotationMol.smiles && refPlot.current) {
      setPlotMoleculeSmiles(annotationMol.smiles);
    }
  }, [annotationMol.smiles]);

  // Handle upvote click
  const handleUpvote = () => {
    if (userVote === 'up') {
      setUpvotes(upvotes - 1);
      setUserVote(null);
    } else {
      setUpvotes(upvotes + 1);
      setUserVote('up');
      if (userVote === 'down') {
        setDownvotes(downvotes - 1);
      }
    }
  };

  // Handle downvote click
  const handleDownvote = () => {
    if (userVote === 'down') {
      setDownvotes(downvotes - 1);
      setUserVote(null);
    } else {
      setDownvotes(downvotes + 1);
      setUserVote('down');
      if (userVote === 'up') {
        setUpvotes(upvotes - 1);
      }
    }
  };

  return (
    <div style={{ padding: '16px' }}>
      <Card
        style={{
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          padding: '16px',
          marginBottom: '16px',
        }}
      >
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col span={4}>
            <Row justify="center" align="middle">
              <Avatar
                size={64}
                style={{ backgroundColor: '#f56a00', verticalAlign: 'middle', marginBottom: '8px' }}
                icon={<UserOutlined />}
              >
                {annotationUser.name[0]}
              </Avatar>
              <Space direction="vertical" style={{ display: 'flex', alignItems: 'center' }}>
                <h3 style={{ marginBottom: 0, fontSize: '18px', fontWeight: 'bold' }}>{annotationUser.name}</h3>
                <p style={{ color: '#777' }}>{new Date(time + 'Z').toLocaleString().split(',')[0]}</p>
              </Space>
            </Row>
          </Col>
          <Col span={16}>
            <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>Molecular name: {annotationMol.name}</p>
            
            {/* Styled SMILES Title */}
            <p style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '10px' }}>SMILES:</p>
            <p style={{ fontSize: '14px', color: '#666', marginBottom: '16px' }}>{annotationMol.smiles}</p>
            
            {/* Styled Adduct Title */}
            <p style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '10px' }}>Adduct:</p>
            <p style={{ fontSize: '14px', color: '#666', marginBottom: '16px' }}>{annotationMol.adduct}</p>
            
            <p style={{ fontSize: '14px', color: '#444' }}>
              <strong>Comment:</strong> {annotationAnnotation.comments || 'No comments available.'}
            </p>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '16px' }}>
              <div>
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease',
                }}
                onClick={handleUpvote}
              >
                <FaThumbsUp
                  size="2em"
                  color={userVote === 'up' ? '#1890ff' : '#bbb'} // Blue for upvoted
                  style={{ transform: userVote === 'up' ? 'scale(1.2)' : 'scale(1)' }}
                />
              </button>
              <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Upvotes: {upvotes}</p>
              </div>
              <div>
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease',
                }}
                onClick={handleDownvote}
              >
                <FaThumbsDown
                  size="2em"
                  color={userVote === 'down' ? '#f5222d' : '#bbb'} // Red for downvoted
                  style={{ transform: userVote === 'down' ? 'scale(1.2)' : 'scale(1)' }}
                />
              </button>
              <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Downvotes: {downvotes}</p>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <Row justify="center" align="middle" ref={refPlot}>
              {contextPlotMolecule}
            </Row>
          </Col>
          <Col span={16}>
          <List
            itemLayout="horizontal"
            dataSource={comment_list}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                  title={`User ${index}`}
                  description={item.comment}
                />
              </List.Item>
            )}
          />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const UserAnnotation = () => {
  const [stateTimelineData, setTimelineData] = useState([]);
  const [stateGlobalAnnotationData] = useAtom(atomAnnotationSearchSelected);

  useEffect(() => {
    console.log(stateGlobalAnnotationData);
    if (stateGlobalAnnotationData.length === 0) {
      setTimelineData([]);
    } else {
      let annotationTableData = stateGlobalAnnotationData.map((element, index) => {
        const annotationData = element.data;
        const annoTime = new Date(annotationData.time_stamp * 1000);
        return {
          children: <UserAnnotationCard element={annotationData} time={annoTime} />,
          key: element.id,
          time: annoTime,
        };
      });
      annotationTableData.sort((a, b) => {
        return b.time - a.time;
      });
      setTimelineData(annotationTableData);
    }
  }, [stateGlobalAnnotationData]);

  return (
    <>
      <div style={{ margin: '20px' }}>
        {stateTimelineData.length > 0 ? (
          <Timeline items={stateTimelineData} />
        ) : (
          <Empty description={'No user annotations.'} />
        )}
      </div>
    </>
  );
};

export default UserAnnotation;
